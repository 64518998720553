import React       from 'react';
import { graphql } from 'gatsby';
import Img         from 'gatsby-image';

import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import { findMedia } from '@interness/web-core/src/components/_helpers';

import { H3 } from '../components/styles';

function MatratzeneinfassbaenderPage(props) {
  const muster = findMedia(props.data.images.media, 'matratzenband').childImageSharp.fixed;
  return (
    <>
      <HeaderMedia id={'matratzeneinfassbaender'}/>
      <Wrapper>
        <Spacer/>
        <Heading>Matratzeneinfassbänder</Heading>
        <Spacer height={20}/>
        <p>Wir legen großes Augenmerk auf Qualität, Komfort und Funktion unserer Matratzeneinfassbänder, die bei der
          Umnähung der Matratzenbezüge eingesetzt werden.</p>
        <Separator/>
        <H3>Muster</H3>
        <div style={{ textAlign: 'center' }}>
          <Img fixed={muster} alt='matratzenband'/>
        </div>
        <Separator/>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default MatratzeneinfassbaenderPage;

export const query = graphql`
    query {
        images: directusMediaCollection(name: {eq: "muster"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;