import styled from '@emotion/styled';
import Img    from 'gatsby-image';

export const StyledImg = styled(Img)`
  border: 2px solid black;
  padding: 25px;
`;

export const H3 = styled.h3`
  text-align: center;
`;