import React                       from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img                         from 'gatsby-image';

import { findMedia } from '@interness/web-core/src/components/_helpers';

const HeaderMedia = ({ id }) => {
  const data = useStaticQuery(query);
  const media = findMedia(data.headerImages.images, id);
  if (media) {
    return <Img fluid={media.childImageSharp.fluid} loading='eager' critical fadeIn={false}/>
  } else {
    return null;
  }
};

export default HeaderMedia;

const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "headers"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1980, maxHeight: 700, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;